  // Github Info styling
  .github-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
  
    .github-logo {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
  
    .github-details {
      display: flex;
      flex-direction: column;
      align-items: center;
  
      .username-repo,
      .stars-forks {
        font-size: 14px;
        margin: 5px;
        display: block;
      }
    }
  }
//--------------------------------------
// .github-info {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     margin: 10px;
  
//     .github-logo {
//       width: 50px;
//       height: 50px;
//       border-radius: 50%;
//     }
  
//     .github-details {
//       display: flex;
//       flex-direction: column;
//       align-items: center;
  
//       .username-repo,
//       .stars-forks {
//         font-size: 14px;
//         margin: 5px;
//       }
//     }
// }
//---------------------------------------
// @import 'variables';
// @import 'mixins';

// .github-info {
//   @include flexbox(column);
//   margin: $small-margin;

//   &__logo {
//     width: 50px;
//     height: 50px;
//     border-radius: 50%;
//   }

//   &__details {
//     @include flexbox(column);
    
//     &__username,
//     &__stats {
//       font-size: 14px;
//       margin: $small-margin;
//     }
//   }
// }
