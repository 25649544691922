@import './GithubInfo.scss';

@import './Header/index';
@import './Sidebar/index';
//@import './Footer/index';
@import './WebpageContent/index';
@import 'variables';

// @import './GithubInfo.scss';

// Reset some default browser styles
body, h1, h2, h3, h4, h5, h6, p, ul, ol {
  margin: 0;
  padding: 0;
}
// // @import 'variables';
// // @import 'mixins';

// // body {
// //   font-family: $primary-font;
// //   margin: 0;
// //   padding: 0;
// //   background-color: $primary-color;
// //   color: $secondary-color;

// //   @media (min-width: 768px) {
// //     background-color: $secondary-color;
// //     color: $primary-color;
// //   }
// // }





// Make a classless HTML element look like a Flexbox container
.flex {
  display: flex;
}

// Basic styling
body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
  margin: 0;
  padding: 0;
  background: #f4f4f4;
}

.container {
  max-width: 1100px;
  margin: auto;
  overflow: hidden;
}

// Header styling
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #50b3a2;
  color: white;
  padding: 20px;
  text-align: center;
}

// .title {
//   padding-left: 240px;
// }

.title {
  display: inline-block; // This will make the div only as wide as its contents
  text-align: left;
  padding-left: 300px;
  // display: flex;
  // flex-direction: column;
  // align-items: flex-start;
  // width: fit-content; // This will make the container as wide as the longest item, the title in this case
  // width: -moz-fit-content;  // Firefox-specific syntax
}

.title h2, .title p {
  margin: 0;  /* Remove default margin */
  padding: 0; /* Remove default padding */
  width: 100%; // This will ensure both the title and description take up the full width of .title
  //width: fit-content; /* Limit width to content */
  
}

.title p {
  white-space: pre-wrap;  /* Allow text to wrap to next line */
}


// Sidebar styling
.sidebar {
  background: #333;
  color: #fff;
  padding: 15px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 220px; //************************ */
}

// Main content styling
.webpage-contents {
  //overflow-y: auto;
  margin-left: 280px;
  padding: 20px;
}


//---------------------------------------

  
  // Footer styling
  footer {
    background: #333;
    color: #fff;
    text-align: center;
    padding: 10px;
    position: fixed;
    bottom: 0;
    width: 100%;
  }

  .construction {
    position: relative;
    width: 100%;
    height: 200px;  // Adjust the height as needed
  }
  
  .first-banner {
    position: absolute;
    width: 100%;
    height: auto;
    transform: rotate(0deg);
    left: 0;
    top: 50px;  // Adjust the position as needed
  }
  
